import React, { useEffect, useState, useContext } from "react";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Header from "../../UiKit/Wizard/Header/Header";
import useTaxForm from "../../Hooks/useTaxForm";
import styles from "../../UiKit/Wizard/Header/Header.module.css";
import CloseModal from "./CloseModal";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import WelcomePage from "./WelcomePage";
import AuthenticationPage from "./AuthenticationPage";
import TaxFormSelectionPage from "./TaxFormSelectionPage";
import TaxFormPassCodePage from "./TaxFormPassCodePage";
import TaxFormPasscodeMethodPage from "./TaxFormPasscodeMethodPage";
import TaxformDownloadPage from "./TaxFormDownload";
import InvalidPasswordPage from "./InvalidPasswordPage";
import AccountLockPage from "./AccountLockPage";
import ServiceUnavailablePage from "./ServiceUnavailablePage";
import SessionExpiredPage from "./SessionExpiredPage";
import DidntGetPasscodeModalTaxForm from "./DidntGetPasscodeModalTaxForm";
import TaxFormContextProvider from "../../Contexts/TaxFormContext";
import ChatbotLayout from "../../UiKit/Chatbot/ChatbotLayout/ChatbotLayout";
import WebChatMenu from "../Webchat/WebchatMenu/WebchatMenu";
import TaxFormHeader from "./TaxFormHeader";
import Loading from "../../UiKit/Wizard/Loading/Loding";
import { translations } from "./translations";
import { TaxFormContext } from "../../Contexts/TaxFormContext";
import EncryptionDecryptionErrorPage from "./EncryptionDecryptionErrorPage";
import TaxFormVerificationMethodPage from "./TaxFormVerificationMethodPage";
import InvalidOTPErrorPage from "./InvalidOTPErrorPage";
import TaxFormPasswordExceededAttemptsPage from "./TaxFormPasswordExceededAttemptsPage";
import TaxFormsNoFormsPage from "./TaxFormsNoFormsPage";

function TaxFormWizard({ sendActivity, TaxFormEvent, sendLoggingEventToBackend, postActivity }) {
  return (
    <TaxFormContextProvider>
      <TaxFormWizardToolHelper
        sendActivity={sendActivity}
        TaxFormEvent={TaxFormEvent}
        sendLoggingEventToBackend={sendLoggingEventToBackend}
        postActivity={postActivity}
      />
    </TaxFormContextProvider>
  );
}


export const Pages = {
  AUTHENTICATION: 1,
  TAX_FORM_SELECTION: 2,
  TAX_FORM_PASSCODE_METHOD: 3,
  TAX_FORM_PASSCODE: 4,
  TAX_FORM_DOWNLOAD: 5,
  TAX_FORM_AUTHENTICATION_LOCKED: 6,
  TAX_FORM_FORBIDDEN: 7,
  TAX_FORM_SERVICE_UNAVAILABLE: 8,
  TAX_FROM_SESSION_EXPIRED: 9,
  TAX_FORM_VERIFICATION_METHOD: 10,
  TAX_FORM_UNAUTHORIZED_OTP: 11,
  TAX_FORM_PASSWORD_EXCEEDED_ATTEMPTS: 12,
  TAX_FORM_PASSCODE_EXCEEDED_ATTEMPTS: 13,
  TAX_FORM_NO_FORMS_AVAILABLE: 14,
};

function TaxFormWizardToolHelper({ sendActivity, sendLoggingEventToBackend, postActivity }) {
  const {
    status,
    currentPage,
    setCurrentPage,
    TaxFormEventData,
    TaxFormEvent,
    openRelativeCloseModal,
    closeModal,
    next,
    isDidNotGetPasscodeModalTaxFormOpen,
    setIsDidNotGetPasscodeModalTaxFormOpen,
    userCredentails,
    setUserCredentails,
  } = useTaxForm();

  const [isLoading, setIsLoading] = useState(true);
  const { currentLanguage } = useLanguage();
  const [taxData, setTaxData] = useState();

  const introPageTranslations = translations[currentLanguage]["RetunToChatButton"];
  const lang = currentLanguage;

  const handleClick = () => {
    openRelativeCloseModal();
  };



  useEffect(() => {
    console.log("***")
    console.log("*** TaxFormEvent: ", TaxFormEvent);
    console.log("***")
    
    if (TaxFormEvent && TaxFormEvent.name === "get_verification_methods_success") {
      setCurrentPage(Pages.TAX_FORM_PASSCODE_METHOD);
      return;
    }
    if (TaxFormEvent && TaxFormEvent.name === "unable_to_validate_otp") {
      setTaxData((prevData) => ({
        ...prevData,
        invalidOtpError: true, 
      }));
      setCurrentPage(Pages.TAX_FORM_PASSCODE); 
    }
    setIsLoading(false);
    if (TaxFormEvent && TaxFormEvent.name === "start_success") {
      setCurrentPage(0);
      setTaxData((prevData) => ({
        ...prevData,
        invalidOtpError: false, 
      }));
    }
    else if (TaxFormEvent && TaxFormEvent.name === "get_available_tax_forms_success" && TaxFormEvent.data) {
      setTaxData((prevData) => ({
        ...prevData,
        availableTaxForms: TaxFormEvent.data,
      }));
      setCurrentPage(Pages.TAX_FORM_SELECTION);
    } else if (TaxFormEvent && TaxFormEvent.name === "send_otp_success" && TaxFormEvent.data) {
      setTaxData((prevData) => ({
        ...prevData,
        otpData: TaxFormEvent.data,
      }));
      setCurrentPage(Pages.TAX_FORM_PASSCODE);
    } else if (TaxFormEvent && TaxFormEvent.name === "generate_tax_form_link_success" && TaxFormEvent.data) {
      setTaxData((prevData) => ({
        ...prevData,
        downloadData: TaxFormEvent.data,
      }));
      setCurrentPage(Pages.TAX_FORM_DOWNLOAD);
    } else if (TaxFormEvent && TaxFormEvent.name === "account_locked_inactivity") {
      setTaxData((prevData) => ({
        ...prevData,
        downloadData: TaxFormEvent.data,
      }));
      sendLoggingEventToBackend({
        intentName: lang === "en" ? "TaxFormsLoginAccountLockInactivity" : "TaxFormsLoginAccountLockInactivitySpanish",
        text: lang === "en" ? "TaxFormsLoginAccountLockInactivity" : "TaxFormsLoginAccountLockInactivitySpanish"
      });
      setCurrentPage(Pages.ACCOUNT_LOCK);
    } else if (TaxFormEvent && TaxFormEvent.name === "exceeded_password_attempts") {
      setTaxData((prevData) => ({
        ...prevData,
        downloadData: TaxFormEvent.data,
      }));
      sendLoggingEventToBackend({
        intentName: lang === "en" ? "TaxFormsLoginExceededPasswordAttempts" : "TaxFormsLoginExceededPasswordAttemptsSpanish",
        text: lang === "en" ? "TaxFormsLoginExceededPasswordAttempts" : "TaxFormsLoginExceededPasswordAttemptsSpanish"
      });
      setCurrentPage(Pages.TAX_FORM_PASSWORD_EXCEEDED_ATTEMPTS);
    }
    else if (TaxFormEvent &&
      TaxFormEvent.name === "system_error"
    ) {
      setTaxData((prevData) => ({
        ...prevData,
        downloadData: TaxFormEvent.data,
      }));
      sendLoggingEventToBackend({
        intentName: lang === "en" ? "TaxFormsSystemError" : "TaxFormsSystemErrorSpanish",
        text: lang === "en" ? "TaxFormsSystemError" : "TaxFormsSystemErrorSpanish"
      });
      setCurrentPage(Pages.TAX_FORM_FORBIDDEN);
    } else if (TaxFormEvent && TaxFormEvent.name === "unavailable"
    ) {
      setTaxData((prevData) => ({
        ...prevData,
        downloadData: TaxFormEvent.data,
      }));
      sendLoggingEventToBackend({
        intentName: lang === "en" ? "TaxFormsServiceUnavailable" : "TaxFormsServiceUnavailableSpanish",
        text: lang === "en" ? "TaxFormsServiceUnavailable" : "TaxFormsServiceUnavailableSpanish"
      });
      setCurrentPage(Pages.TAX_FORM_FORBIDDEN);
    }
    else if (TaxFormEvent && TaxFormEvent.name === "unauthorized"
    ) {
      setTaxData((prevData) => ({
        ...prevData,
        downloadData: TaxFormEvent.data,
      }));
      sendLoggingEventToBackend({
        intentName: lang === "en" ? "TaxFormsUnauthorized" : "TaxFormsnauthorizedSpanish",
        text: lang === "en" ? "TaxFormsUnauthorized" : "TaxFormsUnauthorizedSpanish"
      });

      setCurrentPage(Pages.TAX_FROM_SESSION_EXPIRED);
    }
    else if (TaxFormEvent && TaxFormEvent.name === "get_verification_methods_error__no_verification_methods") {
      sendLoggingEventToBackend({
        intentName: lang === "en" ? "TaxFormsNoFormsAvailable" : "TaxFormsNoFormsAvailableSpanish",
        text: lang === "en" ? "TaxFormsNoFormsAvailable" : "TaxFormsNoFormsAvailableSpanish"
      });
      setCurrentPage(Pages.TAX_FORM_VERIFICATION_METHOD);
    }
    else if (TaxFormEvent && TaxFormEvent.name === "get_available_tax_forms_error__service_no_tax_forms_available") {
      sendLoggingEventToBackend({
        intentName: lang === "en" ? "TaxFormsNoFormsAvailable" : "TaxFormsNoFormsAvailableSpanish",
        text: lang === "en" ? "TaxFormsNoFormsAvailable" : "TaxFormsNoFormsAvailableSpanish"
      });
      setCurrentPage(Pages.TAX_FORM_NO_FORMS_AVAILABLE);
    } else if (TaxFormEvent && TaxFormEvent.name === "exceeded_otp_attempts") {
      setCurrentPage(Pages.TAX_FORM_PASSWORD_EXCEEDED_ATTEMPTS);


    }
  }, [TaxFormEvent]);

  const getContent = () => {
    if (isLoading || status === null) {
      return <div>Loading</div>;
    }
    if (status === "error") {
      return <div>Error</div>;
    }

    return (
      <>
        {currentPage === 0 && <WelcomePage
          onNext={() => {
            setCurrentPage(Pages.AUTHENTICATION);
          }}
          sendLoggingEventToBackend={sendLoggingEventToBackend}
        />}
        {currentPage === 1 && (
          <AuthenticationPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={next}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
            taxData={taxData}
            setCurrentPage={setCurrentPage}
            TaxFormEvent={TaxFormEvent}
            userCredentails={userCredentails}
          />
        )}
        {currentPage === 2 && (
          <TaxFormSelectionPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={next}
            taxData={taxData}
            setTaxData={setTaxData}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
            setCurrentPage={setCurrentPage}
            onSubmit={(selectedOption) => {
              const [taxFormType, taxFormYear, taxformSubType] = selectedOption.split("-");
              const year = parseInt(taxFormYear, 10);


              console.log("Parsed year:", year);

              setTaxData((prevData) => ({
                ...prevData,
                selectedTaxForm: { taxFormType, taxFormYear, taxformSubType },
              }));

              // if (year >= 2022) {
              //   console.log("Redirecting to TAX_FORM_DOWNLOAD");
              //   setCurrentPage(Pages.TAX_FORM_DOWNLOAD);
              // } else {
              //   console.log("Redirecting to TAX_FORM_PASSCODE_METHOD");
              //   /setCurrentPage(Pages.TAX_FORM_PASSCODE_METHOD);
              // }
            }}

          />
        )}
        {currentPage === 3 && (
          <TaxFormPasscodeMethodPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={(data) => {
              setTaxData((prevData) => ({ ...prevData, ...data }));
              setCurrentPage(Pages.TAX_FORM_PASSCODE);
            }}
            taxData={taxData}
            setTaxData={setTaxData}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
            setCurrentPage={setCurrentPage}
          />
        )}
        {currentPage === 4 && (
          <TaxFormPassCodePage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={next}
            taxData={taxData}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
            TaxFormEvent={TaxFormEvent}
            setTaxData={setTaxData}
          />
        )}
        {currentPage === Pages.TAX_FORM_DOWNLOAD &&
          (
            <TaxformDownloadPage
              postActivity={postActivity}
              sendActivity={sendActivity}
              onNext={next}
              taxData={taxData}
              setCurrentPage={setCurrentPage}
              sendLoggingEventToBackend={sendLoggingEventToBackend}
            />
          )}
        {currentPage === Pages.ACCOUNT_LOCK && <AccountLockPage
          setCurrentPage={setCurrentPage}
        />}
        {currentPage === Pages.TAX_FORM_FORBIDDEN && <EncryptionDecryptionErrorPage />}
        {currentPage === Pages.TAX_FORM_SERVICE_UNAVAILABLE && <ServiceUnavailablePage />}
        {currentPage === Pages.TAX_FROM_SESSION_EXPIRED && <SessionExpiredPage />}
        {currentPage === Pages.TAX_FORM_VERIFICATION_METHOD && <TaxFormVerificationMethodPage
          setCurrentPage={setCurrentPage} />}
        {currentPage === Pages.TAX_FORM_UNAUTHORIZED_OTP && <InvalidOTPErrorPage />}
        {currentPage === Pages.TAX_FORM_PASSWORD_EXCEEDED_ATTEMPTS && <TaxFormPasswordExceededAttemptsPage />}
        {currentPage === Pages.TAX_FORM_NO_FORMS_AVAILABLE && <TaxFormsNoFormsPage />}

      </>
    );
  };
  return (
    <ChatbotLayout>
      <WebChatMenu />
      {closeModal.isOpen && <CloseModal type={closeModal.type} />}
      {isDidNotGetPasscodeModalTaxFormOpen && <DidntGetPasscodeModalTaxForm handleClose={() => setIsDidNotGetPasscodeModalTaxFormOpen(false)} />}
      <ChatbotLayout.Header>
        <TaxFormHeader />
      </ChatbotLayout.Header>
      <ChatbotLayout.Wizard>
        <WizardLayout>
          <WizardLayout.Header>
            <Header>
              <div className={styles.title} onClick={handleClick}>
                {introPageTranslations}
              </div>
            </Header>
          </WizardLayout.Header>
          {isLoading && <Loading />}
          {getContent()}
        </WizardLayout>
      </ChatbotLayout.Wizard>
    </ChatbotLayout>
  );
}


export default TaxFormWizard;
