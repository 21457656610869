import UserSession from "../Services/UserSession/UserSession";
import { jwtDecode } from 'jwt-decode';
import dataLayerService from "../Services/DatalayerService";
import useEnrollmentStatusActionHandler from './useEnrollmentStatusActionHandler';
import usePasswordResetActionHandler from "./usePasswordResetActionHandler";
import useAffordabilityToolActionHandler from './useAffordabilityToolActionHandler';
import useM2CActionHandler from './useM2CActionHandler'
import useForgotUsernameActionHandler from './useForgotUsernameActionHandler';
import useTaxFormActionHandler from './useTaxFormActionHandler';
import { InputGroup } from "react-bootstrap";
import { act } from "react";

const useIncomingActionHandler = () => {
    const userSessionService = new UserSession(localStorage, jwtDecode);
    const { handleEnrollmentStatusAction } = useEnrollmentStatusActionHandler();
    const { handlePasswordResetAction } = usePasswordResetActionHandler();
    const { handleAffordabilityToolAction } = useAffordabilityToolActionHandler();
    const { handleM2CAction } = useM2CActionHandler();
    const { handleForgotUsernameAction } = useForgotUsernameActionHandler();
    const { handleTaxFormAction } = useTaxFormActionHandler();
  
    const handleIncomingAction = (action, next) => {
        /**
         * if action is being loaded from previous session do not process
         */
        if (!_isNewAction(action)) return next(action);

        userSessionService.setLastActivity(action);

        console.log("action", action);

        if (_shouldBeLoggedIntoDataLayer(action)) {
            dataLayerService.pushLogEventToDataLayer(action);
        }

        if (_isEnrollmentStatusAction(action)) {
            handleEnrollmentStatusAction(action);
        }

        if (_isAffordabilityToolAction(action)) {
            handleAffordabilityToolAction(action);
        }

        if (_isM2CAction(action)) {
            handleM2CAction(action, next);
        }

        if(_isTaxFormAction(action)){
            handleTaxFormAction(action);
        }

        if (_isPasswordResetAction(action)) {
            userSessionService.setContainsPii(true);
            handlePasswordResetAction(action);
            /**
             * returning here short circuits the middleware so that password reset cards dont render
             */
            return;
        }
        if(_isForgotUsernameAction(action)){
            userSessionService.setContainsPii(true);
            handleForgotUsernameAction(action);
            return;
        }

        return next(action);
    }

    const _isNewAction = (action) => {
        const incomingActivityIdParts = action.payload.activity.id.split("|");
        /**
         * for offline-directline messages
         * - activities coming from offline-directline do not have watermarks, so 
         *   we'll treat them as new activities
         */
        if (incomingActivityIdParts.length < 2) return true;

        const incomingActivityWatermark = incomingActivityIdParts[1];
        if (incomingActivityWatermark <= userSessionService.getWatermark()) return false;
        return true;
    }

    const _shouldBeLoggedIntoDataLayer = (action) => {
        if (!action) return false;
        if (!action.payload) return false;
        if (!action.payload.activity) return false;
        if (!action.payload.activity.value) return false;
        if (!action.payload.activity.value.shouldActivitySendDataLayerEvent) return false;
        return true;
    }

    const _isEnrollmentStatusAction = (action) => {
        if (!action) return false;
        if (!action.payload) return false;
        if (!action.payload.activity) return false;
        if (!action.payload.activity.type) return false;
        if (action.payload.activity.type !== "event") return false;
        if (action.payload.activity.name !== "ENROLLMENT_STATUS") return false;
        return true;
    }

    const _isPasswordResetAction = (action) => {
        const _isExceededAttemptMessage = (action) => {
            if (
                action.payload.activity.text ===
                "Passwords can only be changed once per day. If you need to change your password, wait until tomorrow or call (800) 300-1506 for more help."
            ) {
                return true;
            }
        };
        const _isUserNotFound = (action) => {
            if (
                action.payload.activity.text ===
                "We couldn't find an account with this information on file. Please try again."
            ) {
                return true;
            }
        };
        const _isSuccessfullyChangedPasswordMessage = (action) => {
            if (
                action.payload.activity.text ===
                "You have successfully changed you password. Please try to [login](https://apply.coveredca.com/static/lw-web/login) or continue to ask me questions"
            ) {
                return true;
            }
            return false;
        };
        const _isPasswordResetServerIssue = (action) => {
            if (
                action.payload.activity.text ===
                "This service is temporarily unavailable. Please try again later"
            ) {
                return true;
            }
            return false;
        };
        const _isOTPInputExceededMEssage = (action) => {
            if (
                action.payload.activity.text ===
                "Your account is not available at this time. Please contact Covered California for help."
            ) {
                return true;
            }
            return false;
        };
        const _isOTPRetry = (action) => {
            if (
                action.payload.activity.text === "Please check your OTP and try again."
            ) {
                return true;
            }
            return false;
        };
        const _isOTPSessionExpired = (action) => {
            const activity = action.payload.activity;

            if (activity.attachments && activity.attachments.length > 0) {
                return activity.attachments.some((attachment) => {
                    return (
                        attachment.content &&
                        attachment.content.type === "AdaptiveCard" &&
                        attachment.content.body.some((bodyElement) => {
                            return (
                                bodyElement.type === "TextBlock" &&
                                bodyElement.text ===
                                "You session has expired. Are you ready to continue resetting your password?"
                            );
                        })
                    );
                });
            }

            return false;
        };
        const _isOTPServiceUnavailable = (action) => {
            if (
                action.payload.activity.text ===
                "This service is temporarily unavailable. Please try again later"
            ) {
                return true;
            }
            return false;
        };
        const _isPasswordReset = (action) => {
         
            const activity = action.payload.activity;
            if (activity.attachments && activity.attachments.length > 0) {
                return activity.attachments.some((attachment) => {
                    return (
                        attachment.content &&
                        attachment.content.type === "AdaptiveCard" &&
                        attachment.content.body.some((bodyElement) => {
                            return (
                                bodyElement.type === "TextBlock" &&
                                bodyElement.text ===
                                "Are you sure you want to cancel resetting your password?\n\n\n\n "
                            );
                        })
                    );
                });
            }

            return false;
        };
        const _isPasswordInvalid = (action) => {
            if (
                action.payload.activity.text ===
                "We could not update your password. You may have entered a password that you've already used in the past, a password that contains a dictionary word, or a password that contains a keyboard pattern. Please enter a new password."
            ) {
                return true;
            }
            return false;
        };
        const _isPasswordResetCancelled = (action) => {
            if(action.payload.activity.text === "No problem. What else can I help you with?"){
                return true;
            }
            return false;
        }

        if (!action) return false;
        if (!action.payload) return false;
        if (!action.payload.activity) return false;
        if (!action.payload.activity.type) return false;
        if (action.payload.activity.type !== "message") return false;
        if (_isExceededAttemptMessage(action)) return true;
        if (_isUserNotFound(action)) return true;
        if (_isSuccessfullyChangedPasswordMessage(action)) return true;
        if (_isPasswordResetServerIssue(action)) return true;
        if (_isOTPInputExceededMEssage(action)) return true;
        if (_isOTPRetry(action)) return true;
        if (_isOTPSessionExpired(action)) return true;
        if (_isOTPServiceUnavailable(action)) return true;
        if (_isPasswordReset(action)) return true;
        if (_isPasswordInvalid(action)) return true;
        if (_isPasswordResetCancelled(action)) return true;
        if (!action.payload.activity.attachments) return false;
        if (action.payload.activity.attachments.length === 0) return false;
        if (
            action.payload.activity.attachments[0].contentType !==
            "application/usernameDobCard" &&
            action.payload.activity.attachments[0].contentType !==
            "application/contactUsCard" &&
            action.payload.activity.attachments[0].contentType !==
            "application/otpTypeCard" &&
            action.payload.activity.attachments[0].contentType !==
            "application/passcodeCard" &&
            action.payload.activity.attachments[0].contentType !==
            "application/passwordCard"
        )
            return false;

        return true;
    };

    const  _isForgotUsernameAction = (action) => {

        const _isUserNotFound = (action) => {
                
            if (action.payload.activity.text === "We could not find an account with this information on file. Please try again.") {
                return true;
            }
            return false;
        };


        const isSuccessfulForgotUsername = (action) => {
            if (
                action.payload.activity.text ===
                "If you do not get an email with your username, or cannot login, please reach out to the Covered California Service Center at 1-855-708-4590"
            ) {
                return true;
            }
            return false;
        };
        
        const _isForgotUsernameCancelled = (action) => {
          
            if(action.payload.activity.text === "No problem. What else can I help you with?"){
                return true;
            }
            return false;
        }
        const _isForgotUsername = (action) => {
            const activity = action.payload.activity;      
            if (activity.attachments && activity.attachments.length > 0) {
                return activity.attachments.some((attachment) => {
                    return (
                        attachment.content &&
                        attachment.content.type === "AdaptiveCard" &&
                        attachment.content.body.some((bodyElement) => {
                           // console.log("bodyElement", bodyElement);
                            return (
                                bodyElement.type === "TextBlock" &&
                                bodyElement.text ===
                                "Are you sure you want to cancel retrieving you username?\n\n\n\n "
                            );
                        })
                    );
                });
            }

            return false;
        };
        if (!action) return false;
        if (!action.payload) return false;
        if (!action.payload.activity) return false;
        if (!action.payload.activity.type) return false;
        if (action.payload.activity.type !== "message") return false;
        if (_isUserNotFound(action)){
            return true
        }

        if (isSuccessfulForgotUsername(action)) return true;
        
        if (_isForgotUsername(action)){
            return true;
        } 
        if (_isForgotUsernameCancelled(action)) return true;
      
        if (!action.payload.activity.attachments) return false;
        if (action.payload.activity.attachments.length === 0) return false;
        if (action.payload.activity.attachments[0].contentType !=='application/usernameDobCardForgotUsername' ){
           // console.log("return false");
            return false;
        }
        return true;
    }

    const _isAffordabilityToolAction = (action) => {
        if (!action) return false;
        if (!action.payload) return false;
        if (!action.payload.activity) return false;
        if (!action.payload.activity.type) return false;
        if (action.payload.activity.type !== "event") return false;
        if (action.payload.activity.name !== "affordabilitytool") return false;
        return true;
    };

    const _isM2CAction = (action) => {
        if (!action) return false;
        if (!action.payload) return false;
        if (!action.payload.activity) return false;
        if (!action.payload.activity.type) return false;
        if (action.payload.activity.type !== "event") return false;
        if (action.payload.activity.name !== "SB260_TRANSACTION") return false;
        return true;
    }

    const _isTaxFormAction  = (action) => {
        if (!action) return false;
        if (!action.payload) return false;
        if (!action.payload.activity) return false;
        if (!action.payload.activity.type) return false;
        if (action.payload.activity.type !== "event") return false;
        if (action.payload.activity.name !== "TAXFORMS") return false;
        return true;
    }
    return {
        handleIncomingAction
    }
}

export default useIncomingActionHandler;