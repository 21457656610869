import React, { useState, useEffect, useRef, useContext } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useTaxForm from "../../Hooks/useTaxForm";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import { TaxFormContext } from "../../Contexts/TaxFormContext";
import { translations } from "../../Components/TaxFormWizard/translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function TaxFormPassCodePage({
  taxData,
  sendActivity,
  onNext,
  sendLoggingEventToBackend,
  TaxFormEvent,
  setTaxData,
}) {
  const { passcode, setPasscode, otpType } = useContext(TaxFormContext);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const inputRefs = useRef([]);
  const { currentLanguage } = useLanguage();
  const passcodePageTranslations =
    translations[currentLanguage]["PasscodePage"];
  const NavButton = translations[currentLanguage]["NavButton"];
  const { closeTaxForm, setIsDidNotGetPasscodeModalTaxFormOpen } = useTaxForm();

  const [maskedPhone, setMaskedPhone] = useState("");
  const [maskedEmail, setMaskedEmail] = useState("");
  const { phone, email, invalidOtpError } = taxData; 

  useEffect(() => {
    if (taxData) {
      const redactPhone = phone
      ? `XXX-XXX-${phone.slice(-4).charAt(0).toUpperCase()}${phone.slice(-3)}`
      : "";
    
      const redactEmail = email
        ? `${email.split("@")[0].slice(0, 1)}*********@${email.split("@")[1]}`
        : "";

      setMaskedPhone(redactPhone);
      setMaskedEmail(redactEmail);

      setIsLoading(false);

      if (invalidOtpError) {
        setShowValidationMessage(true);
      }
    }
  }, [taxData]);


  const handlePasscodeChange = (index, newValue) => {
    const newPasscode = passcode.split("");
    newPasscode[index] = newValue;
    const updatedPasscode = newPasscode.join("");
    setPasscode(updatedPasscode);
  
    if (updatedPasscode.length === 6) {
      setIsSubmitDisabled(false);
      setShowValidationMessage(false);
  
    
      if (invalidOtpError) {
        setTaxData((prevData) => ({
          ...prevData,
          invalidOtpError: false,
        }));
      }
    } else {
      setIsSubmitDisabled(true);
    }
  
    if (newValue && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  

  const onSubmit = () => {
    if (passcode.length !== 6) {
      setShowValidationMessage(true);
      return;
    }
  
    if (invalidOtpError) {
      setShowValidationMessage(true);
      return;
    }
  
 
    sendActivity({
      type: "message",
      value: {
        event: "get_tax_form_otp_submission",
        data: { otp: passcode.trim() },
      },
    });
  
    sendLoggingEventToBackend({
      intentName:
        currentLanguage === "en"
          ? "TaxFormsPasscodeEntered"
          : "TaxFormsPasscodeEnteredSpanish",
      text:
        currentLanguage === "en"
          ? "TaxFormsPasscodeEntered"
          : "TaxFormsPasscodeEnteredSpanish",
    });
  };
  
  const renderPasscodeInputs = () => {
    return Array.from({ length: 6 }).map((_, index) => (
      <input
        key={index}
        type="text"
        maxLength="1"
        value={passcode[index] || ""}
        onChange={(e) => handlePasscodeChange(index, e.target.value)}
        ref={(el) => (inputRefs.current[index] = el)}
        style={{
          width: "34px",
          padding: "10px",
          textAlign: "center",
          marginRight: "10px",
          borderRadius: "3px",
          border: `1px solid ${showValidationMessage ? "red" : "#BABABC"}`,
          background: "#FFF",
        }}
      />
    ));
  };

  return (
    <PageLayout>
      <PageLayout.Header>
        <SubHeader subHeader={passcodePageTranslations.subHeader} />
      </PageLayout.Header>
      <PageLayout.Content>
        <Body
          bodyText={
            <>
              <p>
                {passcodePageTranslations.bodyText}{" "}
                {otpType === "phone" ? (
                  <span style={{ fontWeight: "bold" }}>{maskedPhone}</span>
                ) : (
                  <span style={{ fontWeight: "bold" }}>{maskedEmail}</span>
                )}
              </p>
            </>
          }
        />
        <Form>
          <Form.FormGroup>
            {/* <Form.Label>{passcodePageTranslations.enterPasscode}</Form.Label> */}
            <Form.Control>{renderPasscodeInputs()}</Form.Control>
            {showValidationMessage && (
              <InputValidationMessage>
                {invalidOtpError
                  ? passcodePageTranslations.validationMessage1
                  : passcodePageTranslations.validationMessage}
              </InputValidationMessage>
            )}
          </Form.FormGroup>
        </Form>
        <a
          href="#!"
          style={{ color: "#2D77A3", textDecoration: "underline" }}
          onClick={() => setIsDidNotGetPasscodeModalTaxFormOpen(true)}
        >
          {passcodePageTranslations.didNotGetPasscode}
        </a>
      </PageLayout.Content>
      <WizardLayout.Navigation>
        <NavigationButton
          onClick={onSubmit}
          type="primary"
          disabled={isSubmitDisabled}
        >
          {NavButton.NextButton}
        </NavigationButton>
        <NavigationButton type={"secondary"} onClick={() => closeTaxForm()}>
          {NavButton.ExitButton}
        </NavigationButton>
      </WizardLayout.Navigation>
    </PageLayout>
  );
}

export default TaxFormPassCodePage;
