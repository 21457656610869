import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import useTaxForm from "../../Hooks/useTaxForm";
import useChatbot from "../../Hooks/useChatbot";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import useServiceHours from "../../Hooks/useServiceHours";

function AccountLockPage(props) {
  const { closeTaxForm, setCurrentPage, resetTaxFormState } = useTaxForm();
  const { closeTaxFormWizard, openWelcomePage } = useChatbot();
  const { currentLanguage } = useLanguage();
  const accountLockPageTranslations =
    translations[currentLanguage]["AccountLockPage"];
  const NavButton = translations[currentLanguage]["NavButton"];
  const { isServiceHour, serviceHourData } = useServiceHours();

  return (
    <PageLayout>
      <PageLayout.Header>
        <SubHeader subHeader={accountLockPageTranslations.subHeader} />
      </PageLayout.Header>
      <PageLayout.Content>
        <Body
          style={{ fontSize: "16px" }}
          bodyText={
            <>
              <div style={{ marginTop: "10px" }}>
                {accountLockPageTranslations.bodyText}
              </div>
              <div style={{ marginTop: "32px" }}>
                <strong>{accountLockPageTranslations.serviceCenter}</strong>
                <br />
                <a
                  href={`tel:8557084590`}
                  style={{ color: "#2D77A3", textDecoration: "underline" }}
                >
                  {accountLockPageTranslations.phoneNumber}
                </a>
                <br />
                {serviceHourData && serviceHourData.weekDayHours && (
                  <div>
                    {serviceHourData.weekDayHours.map(
                      (hour, index) => hour && <div key={index}>{hour}</div>
                    )}
                    {serviceHourData.extendedHours &&
                      serviceHourData.extendedHours.map(
                        (hour, index) => hour && <div key={index}>{hour}</div>
                      )}
                  </div>
                )}
              </div>
            </>
          }
        />
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton
          onClick={() => closeTaxFormWizard()}
          type="primary"
          style={{ marginBottom: "36px" }}
        >
          {NavButton.ExitButton}
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

export default AccountLockPage;
