import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import useTaxForm from "../../Hooks/useTaxForm";
import PdfIcon from "../../Images/Pdf.svg";

function TaxformDownloadPage({
  taxData,
  setCurrentPage,
  sendLoggingEventToBackend,
}) {
  const { closeTaxForm, resetTaxFormState } = useTaxForm();
  const lang = currentLanguage;

  let selectedTaxForm = {};
  if (taxData && taxData.selectedTaxForm) {
    selectedTaxForm = taxData.selectedTaxForm;
  }

  let downloadLink = "";
  if (taxData && taxData.downloadData && taxData.downloadData.downloadLink) {
    downloadLink = taxData.downloadData.downloadLink;
  }

  let taxFormSubType = "";
  if (
    taxData &&
    taxData.availableTaxForms &&
    taxData.availableTaxForms.availableTaxForms
  ) {
    const matchingForm = taxData.availableTaxForms.availableTaxForms.find(
      (form) =>
        form.taxFormType === selectedTaxForm.taxFormType &&
        form.taxFormYear === parseInt(selectedTaxForm.taxFormYear, 10)
    );
    taxFormSubType = matchingForm ? matchingForm.taxFormSubType : "";
  }

  const handleDownload = () => {
    if (downloadLink) {
      const anchor = document.createElement("a");
      anchor.href = downloadLink;
      anchor.target = "_blank";
      anchor.download = "";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      sendLoggingEventToBackend({
        intentName:
          lang === "en"
            ? "TaxFormsDownloadAvailable"
            : "TaxFormsDownloadAvailable",
        text:
          lang === "en"
            ? "TaxFormsDownloadAvailable"
            : "TTaxFormsDownloadAvailableSpanish",
      });
    } else {
    }
  };

  const handleGetAnotherTaxForm = () => {
    resetTaxFormState();
    setCurrentPage(0);
  };
  const { currentLanguage } = useLanguage();
  const downloadPageTranslations =
    translations[currentLanguage]["DownloadPage"];

  return (
    <PageLayout>
      <PageLayout.Header>
        <SubHeader
          subHeader={`Here's your ${selectedTaxForm.taxFormType || ""} form`}
        />
      </PageLayout.Header>
      <PageLayout.Content>
        <Body
          bodyText={
            <>
              <p>{downloadPageTranslations.bodyText}</p>
            </>
          }
        />
        <div style={{ margin: "32px 0", textAlign: "center" }}>
          <button
            onClick={handleDownload}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              background: "#F1F6F9",
              border: "1px solid #D1D1D1",
              borderRadius: "8px",
              padding: "16px 33px",
              cursor: "pointer",
              fontSize: "14px",
              color: "#2d77a3",
              fontWeight: "400",
              gap: "6px",
              fontFamily: "Open Sans",
              lineHeight: "20px",
              textDecoration: "underline",
              width: "100%",
              height: "72px",
              textAlign: "left",
            }}
          >
            <img
              src={PdfIcon}
              alt="PDF Icon"
              style={{
                width: "24px",
                height: "24px",
              }}
            />
            {/* {`Download My ${selectedTaxForm.taxFormYear} ${selectedTaxForm.taxFormType} -${taxData.taxFormSubType}`  } */}
            {`Download My ${selectedTaxForm.taxFormYear} ${selectedTaxForm.taxFormType} -${taxFormSubType}`}
          </button>
        </div>

        <Body
          bodyText={
            <>
              <p>
                To see your past tax forms,{" "}
                <a
                  href="https://apply.coveredca.com/static/lw-web/login"
                  target="_blank"
                  style={{ color: "#2d77a3", textDecoration: "underline" }}
                >
                  log in
                </a>{" "}
                and visit your account dashboard.
              </p>
            </>
          }
        />
      </PageLayout.Content>
      <WizardLayout.Navigation>
        <NavigationButton
          onClick={() => {
            closeTaxForm();
            sendLoggingEventToBackend({
              intentName:
                lang === "en"
                  ? "TaxFormsDownloadExit"
                  : "TaxFormsDownloadExitSpanish",
              text:
                lang === "en"
                  ? "TaxFormsDownloadExit"
                  : "TaxFormsDownloadExitSpanish",
            });
          }}
          type={"primary"}
        >
          Exit
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            sendLoggingEventToBackend({
              intentName:
                lang === "en"
                  ? "TaxFormsDownloadAnother"
                  : "TaxFormsDownloadAnotherSpanish",
              text:
                lang === "en"
                  ? "TaxFormsDownloadAnother"
                  : "TaxFormsDownloadAnotherSpanish",
            });
            handleGetAnotherTaxForm();
          }}
          type={"secondary"}
        >
          Get Another Tax Form
        </NavigationButton>
      </WizardLayout.Navigation>
    </PageLayout>
  );
}

export default TaxformDownloadPage;
