import React, {createContext, useState } from "react";

export const TaxFormContext = createContext();

const TaxFormContextProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isTaxFormWizardOpen, setIsTaxFormWizardOpen] = useState(true);
    const [isTaxFormMenuOpen, setIsTaxFormMenuOpen] = useState(false);
    const [closeModal, setCloseModal] = useState({isOpen: false, type: null});
    const [helpModal, setHelpModal] = useState({isOpen: false, type: null})
    const [offBusinessHoursHelpModal, setOffBusinessHoursHelpModal] = useState({isOpen: false, type: null})
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [passcode, setPasscode] = useState("");
    const [isDidNotGetPasscodeModalTaxFormOpen, setIsDidNotGetPasscodeModalTaxFormOpen] = useState(false);
    const [availableTaxForms, setAvailableTaxForms] = useState([]);
    const [taxData, setTaxData] = useState(null);
    const [otpType, setOtpType] = useState(null);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [maskedPhone, setMaskedPhone] = useState('');
    const [maskedEmail, setMaskedEmail] = useState('');
    const [userCredentails, setuserCredentails] = useState(""); 


    const value = {
        currentPage,
        setCurrentPage,
        isTaxFormWizardOpen,
        setIsTaxFormWizardOpen,
        closeModal,
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
        isTaxFormMenuOpen,
        setIsTaxFormMenuOpen,
        userName,
        setUserName,
        password,
        setPassword,
        passcode,
        setPasscode,
        isDidNotGetPasscodeModalTaxFormOpen,
        setIsDidNotGetPasscodeModalTaxFormOpen,
        availableTaxForms,
        setAvailableTaxForms,
        setTaxData,
        setEmail,
        setPhone,
        maskedEmail,
        setMaskedEmail,
        maskedPhone,
        setMaskedPhone,
        phone,
        email,
        setOtpType,
        otpType,
        userCredentails,
        setuserCredentails
        
    };
    return (
        <TaxFormContext.Provider value={value}>
            {children}
        </TaxFormContext.Provider>
    );
}

export default TaxFormContextProvider;