import React, { useState, useContext, useEffect } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useTaxForm from "../../Hooks/useTaxForm";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import classes from "../../UiKit/InputValidationMessage/InputValidationMessage.module.css";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { TaxFormContext } from "../../Contexts/TaxFormContext";
import { Pages } from "./TaxFormWizard";

function TaxFormPasscodeMethodPage({
  postActivity,
  sendActivity,
  onNext,
  sendLoggingEventToBackend,
}) {
  const { otpType, setOtpType } = useContext(TaxFormContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const { currentLanguage } = useLanguage();
  const otpTypePageTranslations = translations[currentLanguage]["OtpTypePage"];
  const { TaxFormEvent, closeTaxForm, setCurrentPage } = useTaxForm();
  const NavButton = translations[currentLanguage]["NavButton"];
  const lang = currentLanguage;

  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  // const { setPhone, setEmail } = useContext(TaxFormContext);

  useEffect(
    () => {
      if (TaxFormEvent && TaxFormEvent.data) {
        const { otpPhoneNumber, optEmailId } = TaxFormEvent.data;

        setPhone(otpPhoneNumber || null);
        setEmail(optEmailId || null);

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    },
    [TaxFormEvent],
    setPhone,
    setEmail
  );

  const redactPhone = phone
      ? `XXX-XXX-${phone.slice(-4).charAt(0).toUpperCase()}${phone.slice(-3)}`
      : "";
  const redactEmail = (email) => {
    if (!email) return "";
    const [localPart, domain] = email.split("@");
    return `${localPart.slice(0, 1)}*********@${domain}`;
  };

  const radioOptions = [];
  if (email) {
    radioOptions.push({
      label: (
        <>
          {otpTypePageTranslations.email}:{" "}
          <span style={{ fontWeight: "bold" }}>{redactEmail(email)}</span>
        </>
      ),
      value: "email",
    });
  }
  if (phone) {
    radioOptions.push({
      label: (
        <>
          {otpTypePageTranslations.phone}:{" "}
          <span style={{ fontWeight: "bold" }}>{redactPhone}</span>
        </>
      ),
      value: "phone",
    });
  }
  

  const onSelect = (e) => {
    setOtpType(e.target.value);
    setShowValidationError(false);
  };

  const onSubmit = () => {
    if (otpType) {
      sendActivity({
        type: "message",
        value: {
          event: "get_tax_form_verification_method_selection",
          data: {
            otpType,
            phoneNo: phone,
            email: email,
            locale: "en",
            screenIdentif: "ACC_HOME_IMP_DOC",
          },
        },
      });
      sendLoggingEventToBackend({
        intentName: lang === "en" ? "TaxFormsGetOTP" : "TaxFormsGetOTPSpanish",
        text: lang === "en" ? "TaxFormsGetOTP" : "TaxFormsGetOTPSpanish",
      });
      setIsDisabled(true);
      setShowValidationError(false);
      onNext({ phone, email });
    } else {
      setShowValidationError(true);
    }
  };

  // if (isLoading ||  TaxFormEvent.data === 'undefined' || !TaxFormEvent.data) {
  //   // return (
  //   //   <div style={{ textAlign: "center", marginTop: "20px", fontSize: "16px", color: "#334048" }}>
  //   //     Loading, please wait...
  //   //   </div>
  //   // );
  //   setCurrentPage(Pages.TAX_FORM_VERIFICATION_METHOD);
  // }

  return (
    <PageLayout>
      <PageLayout.Header style={{ marginBottom: "8px" }}>
        <SubHeader subHeader={otpTypePageTranslations.subHeader} />
      </PageLayout.Header>
      <PageLayout.Content>
        <Form>
          <Form.FormGroup style={{ marginTop: "8px" }}>
            <Body
              bodyText={
                <div style={{ marginTop: "10px" }}>
                  {otpTypePageTranslations.bodyText}
                </div>
              }
            />
            <div style={{ marginTop: "16px", marginBottom: "32px" }}>
              {radioOptions.map((option) => (
                <label
                  key={option.value}
                  style={{ display: "block", marginBottom: "12px" }}
                >
                  <input
                    type="radio"
                    name="otpType"
                    value={option.value}
                    checked={otpType === option.value}
                    onChange={onSelect}
                    disabled={isDisabled}
                    style={{ marginRight: "12px" }}
                  />
                  {option.label}
                </label>
              ))}
            </div>
            {showValidationError && (
              <InputValidationMessage
                className={classes["otp-validation-message"]}
              >
                {otpTypePageTranslations.validationMessage}
              </InputValidationMessage>
            )}
          </Form.FormGroup>
        </Form>
      </PageLayout.Content>
      <WizardLayout.Navigation>
        <NavigationButton onClick={onSubmit} type={"primary"}>
          {NavButton.NextButton}
        </NavigationButton>
        <NavigationButton type={"secondary"} onClick={() => closeTaxForm()}>
          {NavButton.ExitButton}
        </NavigationButton>
      </WizardLayout.Navigation>
    </PageLayout>
  );
}

export default TaxFormPasscodeMethodPage;
