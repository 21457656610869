import React, { useState, useContext, useRef, useEffect } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useTaxForm from "../../Hooks/useTaxForm";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import { TaxFormContext } from "../../Contexts/TaxFormContext";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";
import useWebChat from "../../Hooks/useWebchat/useWebChat";
import RecoveryForgotUserNameModal from "./RecoveryForgotUserNameModal";
import RecoveryForgotPasswordModal from "./RecoveryForgotPasswordModal";
import { Pages } from "./TaxFormWizard";
import Alert from "../../UiKit/Wizard/Alert/Alert";

function AuthenticationPage(props) {
  const { closeTaxForm } = useTaxForm();
  const { currentLanguage } = useLanguage();
  const { sendActivity, TaxFormEvent } = props;
  const AuthenticationPageTranslations =
    translations[currentLanguage]["AuthenticationPage"];
  const NavButton = translations[currentLanguage]["NavButton"];
  const {
    userName,
    setUserName,
    password,
    setPassword,
    setAvailableTaxForms,
    userCredentails,
    setuserCredentails,
  } = useContext(TaxFormContext);
  const [isUsernameValidationVisible, setIsUsernameValidationVisible] =
    useState(false);
  const [isPasswordValidationVisible, setIsPasswordValidationVisible] =
    useState(false);
  const { sendMessage } = useWebChat();
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);
  const [showRecoverPasswordModal, setShowRecoverPasswordModal] =
    useState(false);
  const lang = currentLanguage;
  const [showLoginFailedAlert, setShowLoginFailedAlert] = useState(false);

  const alertRef = useRef();

  useEffect(() => {
    if (TaxFormEvent && TaxFormEvent.name === "login_failed") {
      setShowLoginFailedAlert(true);
      props.sendLoggingEventToBackend({
        intentName:
          lang === "en" ? "TaxFormsLoginFailed" : "TaxFormsLoginFailedSpanish",
        text:
          lang === "en" ? "TaxFormsLoginFailed" : "TaxFormsLoginFailedSpanish",
      });
    }
    if (
      TaxFormEvent &&
      TaxFormEvent.name === "get_available_tax_forms_success"
    ) {
      props.sendLoggingEventToBackend({
        intentName:
          lang === "en"
            ? "TaxFormsAuthenticationSucceeded"
            : "TaxFormsAuthenticationSucceededSpanish",
        text:
          lang === "en"
            ? "TaxFormsAuthenticationSucceeded"
            : "TTaxFormsAuthenticationSucceededpanish",
      });
    }
  }, [TaxFormEvent]);

  const linkStyle = {
    color: "var(--CalHEERS-Main-Blue, #2D77A3)",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    textDecorationLine: "underline",
  };

  const onSubmit = () => {
    let hasError = false;

    if (!userName || userName.trim() === "") {
      setIsUsernameValidationVisible(true);
      hasError = true;
    } else {
      setIsUsernameValidationVisible(false);
    }

    if (!password || password.trim() === "") {
      setIsPasswordValidationVisible(true);
      hasError = true;
    } else {
      setIsPasswordValidationVisible(false);
    }

    if (hasError) {
      return;
    }

    setShowLoginFailedAlert(false);

    sendActivity({
      type: "message",
      value: {
        event: "get_tax_form_login",
        data: {
          username: userName,
          password: password,
          url: "url",
        },
      },
    });

    if (
      TaxFormEvent &&
      TaxFormEvent.name ===
        "get_available_tax_forms_error__service_no_tax_forms_available"
    ) {
      props.setCurrentPage(Pages.TAX_FORM_VERIFICATION_METHOD);
      return;
    }
  };

  return (
    <>
      <WizardLayout.Content>
        <PageLayout>
          <PageLayout.Header style={{ marginBottom: "8px" }}>
            <SubHeader subHeader={AuthenticationPageTranslations.header} />
          </PageLayout.Header>
          <PageLayout.Content>
            <Body
              bodyText={
                <>
                  <div style={{ marginTop: "0px" }}>
                    {AuthenticationPageTranslations.subHeader}
                  </div>
                </>
              }
            />
            <Form>
              <Form.FormGroup>
                <Form.Label>
                  {AuthenticationPageTranslations.username}
                </Form.Label>
                <Form.Control>
                  <Input
                    label={AuthenticationPageTranslations.UserName}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    type="text"
                    placeholder={
                      AuthenticationPageTranslations.userNamePlaceholder
                    }
                  />
                  {isUsernameValidationVisible && (
                    <InputValidationMessage>
                      {AuthenticationPageTranslations.validationUsernameMessage}
                    </InputValidationMessage>
                  )}
                </Form.Control>
                <a
                  href="#!"
                  style={linkStyle}
                  onClick={() => setShowRecoveryModal(true)}
                >
                  {AuthenticationPageTranslations.forgotUsername}
                </a>
              </Form.FormGroup>
              <Form.FormGroup>
                <Form.Label>
                  {AuthenticationPageTranslations.password}
                </Form.Label>
                <Form.Control>
                  <Input
                    label={AuthenticationPageTranslations.password}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder={
                      AuthenticationPageTranslations.passwordPlaceholder
                    }
                  />
                  {isPasswordValidationVisible && (
                    <InputValidationMessage>
                      {AuthenticationPageTranslations.validationPasswordMessage}
                    </InputValidationMessage>
                  )}
                </Form.Control>
                <a
                  href="#!"
                  style={linkStyle}
                  onClick={() => setShowRecoverPasswordModal(true)}
                >
                  {AuthenticationPageTranslations.forgotYourPassword}
                </a>
              </Form.FormGroup>
            </Form>
          </PageLayout.Content>
          {showLoginFailedAlert && (
            <div
              ref={(ref) => {
                if (ref) {
                  alertRef.current = ref;
                  ref.focus();
                  ref.scrollIntoView({ behavior: "smooth", block: "center" });
                }
              }}
              tabIndex={-1}
              style={{ marginTop: "32px" }}
            >
              <Alert
                iconType="red"
                frameIcon="red"
                message="We couldn't find an account with this information."
              />
            </div>
          )}
        </PageLayout>
      </WizardLayout.Content>

      {props.userCredentails && (
        <div
          ref={(ref) => {
            if (ref) {
              alertRef.current = ref;
              ref.focus();
              ref.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }}
          tabIndex={-1}
        >
          <Alert
            iconType="red"
            frameIcon="red"
            message="We could not find an account with this information. "
          />
        </div>
      )}
      <WizardLayout.Navigation>
        <NavigationButton type={"primary"} onClick={onSubmit}>
          {NavButton.NextButton}
        </NavigationButton>
        <NavigationButton type={"secondary"} onClick={() => closeTaxForm()}>
          {NavButton.ExitButton}
        </NavigationButton>
      </WizardLayout.Navigation>
      {showRecoveryModal && (
        <RecoveryForgotUserNameModal
          type="fixed"
          insideWizard={true}
          handleClose={() => setShowRecoveryModal(false)}
          closeTaxForm={closeTaxForm}
        />
      )}
      {showRecoverPasswordModal && (
        <RecoveryForgotPasswordModal
          type="fixed"
          insideWizard={true}
          handleClose={() => setShowRecoverPasswordModal(false)}
          closeTaxForm={closeTaxForm}
        />
      )}
    </>
  );
}

export default AuthenticationPage;
