import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import useTaxForm from "../../Hooks/useTaxForm";
import useChatbot from "../../Hooks/useChatbot";
import useServiceHours from "../../Hooks/useServiceHours";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function TaxFormsNoFormsPage(props) {
    const { closeTaxForm, setCurrentPage, resetTaxFormState } = useTaxForm();
    useTaxForm();
    const {closeTaxFormWizard, openWelcomePage} = useChatbot();
  const { isServiceHour, serviceHourData } = useServiceHours();
  const { currentLanguage } = useLanguage();
  const serviceCenterTranslations = translations[currentLanguage]["NoTaxformPage"];
  const NavButton = translations[currentLanguage]["NavButton"];

  return (
    <PageLayout>
      <PageLayout.Header>
        <SubHeader subHeader={serviceCenterTranslations.subHeader} />
      </PageLayout.Header>
      <PageLayout.Content>
        <Body
          style={{ fontSize: "16px" }}
          bodyText={
            <>
              <div style={{ marginTop: "10px" }}>
               {serviceCenterTranslations.bodyText}
              </div>
              <div style={{ marginTop: "32px" }}>
                <strong>{serviceCenterTranslations.serviceCenter}</strong>
                <br />
                <a
                  href={`tel:8557084590`}
                  style={{ color: "#2D77A3", textDecoration: "underline" }}
                >
                  {serviceCenterTranslations.phoneNumber}
                </a>
                <br />
                {serviceHourData && serviceHourData.weekDayHours && (
                  <div>
                    {serviceHourData.weekDayHours.map(
                      (hour, index) => hour && <div key={index}>{hour}</div>
                    )}
                    {serviceHourData.extendedHours &&
                      serviceHourData.extendedHours.map(
                        (hour, index) => hour && <div key={index}>{hour}</div>
                      )}
                  </div>
                )}
              </div>
            </>
          }
        />
      </PageLayout.Content>
      <PageLayout.Navigation>
                <NavigationButton 
                // onClick={() => closePasswordReset()} 
                 onClick={() => closeTaxFormWizard()}
               // onClick={handleStartOver}
                type="primary" style={{ marginBottom: "36px" }}>
             {serviceCenterTranslations.Exit}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}


export default TaxFormsNoFormsPage;