import { useContext } from "react";
import { ChatbotContext } from "../Contexts/ChatbotContext";
import useChatbot from "./useChatbot";
import useWebChat from "./useWebChat";
import { useLanguage } from "../UiKit/Wizard/langugeContext";
import { TaxFormContext } from "../Contexts/TaxFormContext";

function useTaxForm() {
    const {
        isTaxFormWizardOpen,
        isMenuOpen,
        setIsMenuOpen,
        currentPage,
        setCurrentPage,
        taxFormWizardEventData,
        closeModal,
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
        isDidNotGetPasscodeModalTaxFormOpen,
        setIsDidNotGetPasscodeModalTaxFormOpen,
        setTaxData,
        setUserName,
        setPassword,
        setPasscode,
        setOtpType,
        setPhone,
        setEmail,
        setMaskedPhone,
        setMaskedEmail,
        setAvailableTaxForms, 
        
 
    } = useContext(TaxFormContext);
    
    const { setIsTaxFormWizardOpen, TaxFormEvent } = useContext(ChatbotContext);
    const chatbotHook = useChatbot();
    const { sendActivity } = useWebChat();
    const { currentLanguage } = useLanguage();
    
    const closeTaxForm = () => {
        setIsTaxFormWizardOpen(false);
        // sendActivity(
        // currentLanguage === "en"
        //     ? { text: "My Medi-Cal is ending", type: "message" }
        //     : { text: "Mi Medi-Cal está terminando", type: "message" }
        // );
    };

    const resetTaxFormState = () => {
      setTaxData(null);
      setCurrentPage(0);
      setUserName("");
      setPassword("");
      setPasscode("");
      setOtpType(null);
      setPhone("");
      setEmail("");
      setMaskedPhone("");
      setMaskedEmail("");
      setAvailableTaxForms([]);
    };
    
   
    const next = () => {
      console.log("Current Page Before:", currentPage);
      setCurrentPage((prevPage) => {
        console.log("Current Page After:", prevPage + 1);
        return prevPage + 1;
      });
    };
    
    
      const previous = () => {
        if (currentPage === 0) return;
        setCurrentPage(currentPage - 1);
      };
    
      const openFixedCloseModal = () => {
        setCloseModal({isOpen: true, type: "fixed"});
    };
    const openRelativeCloseModal = () => {
        setCloseModal({isOpen: true, type: "relative"});
    };
    const closeCloseModal = () => {
        setCloseModal({isOpen: false, type: null});
    }
    
    const openFixedHelpModal = () => {
      setHelpModal({isOpen: true, type: "fixed"});
    };
    const openRelativeHelpModal = () => {
      setHelpModal({isOpen: true, type: "relative"});
    };
    
    const closePhoneSupportModal = () => {
      setHelpModal({isOpen: false, type: null});
    }
    
    const openFixedOffHoursHelpModal = () => {
      setOffBusinessHoursHelpModal({isOpen: true, type: "fixed"});
    };
    const openRelativeOffHoursHelpModal = () => {
      setOffBusinessHoursHelpModal({isOpen: true, type: "relative"});
    };
    
    const closePhoneSupportOffHoursModal = () => {
      setOffBusinessHoursHelpModal({isOpen: false, type: null});
    }
    
    return {
  closeTaxForm,
  currentPage,
    setCurrentPage,
    next,
    previous,
    TaxFormEvent,
    taxFormWizardEventData,
    closeModal,
    closeCloseModal,
    openFixedCloseModal,
    openRelativeCloseModal,
    helpModal,
    openFixedHelpModal,
    openRelativeHelpModal,
    closePhoneSupportModal,
    openFixedOffHoursHelpModal,
    openRelativeOffHoursHelpModal,
    closePhoneSupportOffHoursModal,
    offBusinessHoursHelpModal,
    isMenuOpen,
    setIsDidNotGetPasscodeModalTaxFormOpen,
    isDidNotGetPasscodeModalTaxFormOpen,
    resetTaxFormState,
    ...chatbotHook,

    };
    }

    export default useTaxForm;
