import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import useTaxForm from "../../Hooks/useTaxForm";
import useChatbot from "../../Hooks/useChatbot";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function EncryptionDecryptionErrorPage(props) {
    const { closeTaxForm, setCurrentPage, resetTaxFormState } = useTaxForm();
    const {closeTaxFormWizard, openWelcomePage} = useChatbot();
    const { currentLanguage } = useLanguage();
    const EncryptionDecryptionErrorPageTranslations = translations[currentLanguage]["EncryptionDecryptionErrorPage"];
    const NavButton = translations[currentLanguage]["NavButton"];

    return (
        <PageLayout>
            <PageLayout.Content>
                 <Alert
                        iconType='red'
                        frameIcon='red'
                        highlightedMessage={EncryptionDecryptionErrorPageTranslations.Title}
                        message={EncryptionDecryptionErrorPageTranslations.subHeader}
                    />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton 
                // onClick={() => closePasswordReset()} 
                 onClick={() => closeTaxFormWizard()}
               // onClick={handleStartOver}  
                type="primary" style={{ marginBottom: "36px" }}>
               {NavButton.ExitButton}   
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default EncryptionDecryptionErrorPage;