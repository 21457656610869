import { useState, useEffect } from 'react';
import isServiceOpen from '../Utilities/ServiceHours';
import { useLanguage } from '../UiKit/Wizard/langugeContext';

const useServiceHour = () => {
  const [isServiceHour, setIsServiceHour] = useState(null);
  const [serviceHourData, setServiceHourData] = useState(null);
  const { currentLanguage  } = useLanguage();

  useEffect(() => {
    const fetchServiceHour = async () => {
      try {
        let url1;
        if (process.env.NODE_ENV === 'development') {
          url1 = 'https://spring-coconut.cloudvent.net/api/hours.json';
        } else {
          url1 = 'https://www.coveredca.com/api/hours.json';
        }

        const response = await fetch(url1);
        const data = await response.json();
        const result = isServiceOpen(data, currentLanguage );
        setIsServiceHour(result);
        setServiceHourData(data[currentLanguage ]);
      } catch (error) {
        console.error('Error fetching service data: ', error);
      }
    };

    if (currentLanguage) {
      fetchServiceHour();
    }
  }, [currentLanguage]); 

  return { isServiceHour, serviceHourData };
};

export default useServiceHour;
