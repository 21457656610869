import useChatbot from "./useChatbot";

const useTaxFormActionHandler = () => {
    const { openTaxFormWizard } = useChatbot();

    const handleTaxFormAction = (action) => {
        openTaxFormWizard(action.payload.activity.value);
    }

    return {
        handleTaxFormAction
    }
}

export default useTaxFormActionHandler;