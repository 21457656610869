import React, { useEffect, useState } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import ChatbotRadio from "../../UiKit/ChatbotRadio/ChatbotRadio";
import useTaxForm from "../../Hooks/useTaxForm";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { use } from "react";

function TaxFormSelectionPage({
  sendActivity,
  sendLoggingEventToBackend,
  onSubmit,
}) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [showValidation, setShowValidation] = useState(false);
  const { TaxFormEvent, closeTaxForm } = useTaxForm();
  const { currentLanguage } = useLanguage();
  const lang = currentLanguage;

  useEffect(() => {
    if (
      TaxFormEvent &&
      TaxFormEvent.data &&
      Array.isArray(TaxFormEvent.data.availableTaxForms)
    ) {
      const transformedOptions = TaxFormEvent.data.availableTaxForms.map(
        (form) => ({
          label: `${form.taxFormYear} ${form.taxFormType} - ${form.taxFormSubType}`,
          value: `${form.taxFormType}-${form.taxFormYear}`,
        })
      );

      setOptions(transformedOptions);
    } else {
      console.error("No availableTaxForms found in TaxFormEvent ");
    }
  }, [TaxFormEvent]);

  const handleSelection = (value) => {
    setSelectedOption(value);
    setShowValidation(false);
  };

  const handleSubmit = () => {
    if (!selectedOption) {
      setShowValidation(true);
    } else {
      onSubmit(selectedOption);
      if (sendActivity) {
        sendActivity({
          type: "message",
          value: {
            event: "get_tax_form_tax_form_selection",
            data: {
              taxFormType: selectedOption.split("-")[0],
              taxFormYear: selectedOption.split("-")[1],
            },
          },
        });

        sendLoggingEventToBackend({
          intentName:
            lang === "en"
              ? "TaxFormsTypeSelected"
              : "TaxFormsTypeSelectedSpanish",
          text:
            lang === "en"
              ? "TaxFormsTypeSelected"
              : "TaxFormsTypeSelectedSpanish",
        });
      }
    }
  };

  return (
    <>
      <WizardLayout.Content>
        <PageLayout>
          <PageLayout.Header>
            <SubHeader
              subHeader="Select Your Tax Form"
              style={{ marginBottom: "8px" }}
            />
          </PageLayout.Header>
          <PageLayout.Content>
            <Body
              bodyText={
                <>
                  <div style={{ marginTop: "0px", marginBottom: "16px" }}>
                    Which form from the most recent tax year would you like to
                    download?
                  </div>
                </>
              }
            />
            <ChatbotRadio
              options={options}
              name="taxForm"
              value={selectedOption}
              onChange={handleSelection}
              shouldShowValidation={showValidation}
              inputValidationMessage="Please select a tax form to continue."
            />
          </PageLayout.Content>
        </PageLayout>
      </WizardLayout.Content>
      <WizardLayout.Navigation>
        <NavigationButton type="primary" onClick={handleSubmit}>
          Submit
        </NavigationButton>
        <NavigationButton type="secondary" onClick={() => closeTaxForm()}>
          Exit
        </NavigationButton>
      </WizardLayout.Navigation>
    </>
  );
}

export default TaxFormSelectionPage;
