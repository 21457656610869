import React, {useState} from 'react';
import Modal from '../../UiKit/Modal/Modal';
import NavigationButton, { NavigationButtonGroup } from '../../UiKit/Wizard/NavigationButton/NavigationButton';
import Body from "../../UiKit/Wizard/Body/Body";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import sendEventToDataLayer from '../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer';
import closeTaxForm from "../../Hooks/useTaxForm";
import useWebChat from '../../Hooks/useWebchat/useWebChat';
import closeTaxFormWizard from "../../Hooks/useChatbot";



function RecoveryForgotPasswordModal({ type, insideWizard, handleClose, closeTaxForm }) {
    const { currentLanguage } = useLanguage();
    const recoveryModalTranslations = translations[currentLanguage]["RecoveryModalForPasswordReset"];
    const { sendMessage } = useWebChat();

    const onForgotPassword = () => {
        sendEventToDataLayer({
            event: "chatbot",
            eventDetails: {
                category: "CiCi",
                action: "Forgot Password Flow",
                label: "'Forgot Password Link Click",
            },
        });
        const message = currentLanguage === "en" ?  "Password Reset" : "Olvidé mi contraseña";

   
        closeTaxForm();

 
        setTimeout(() => {
            sendMessage(message);
        }, 1000);
    };

    return (
        <Modal type={type}>
       <Modal.Dialog style={{ height: "260px", width: "280px" }}>
                <Modal.Header>
                    <Modal.Close onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title style={{ fontSize: "18px", lineHeight: "24px" }}>
                        {recoveryModalTranslations.Title}
                    </Modal.Title>
                    <Modal.Content>
                        <Body
                            bodyText={
                                <>
                                    <div style={{ marginTop: "0px", fontSize: "14px",lineHeight: "20px",fontStyle: "normal",fontWeight: 400,color: "var(--CalHEERS-Primary-Text, #334048)"}}>
                                        {recoveryModalTranslations.bodyText}
                                    </div>
                                </>
                            }
                        />
                    </Modal.Content>
                    <NavigationButtonGroup
                        // style={{
                        //     paddingTop: "24px",
                        //     paddingBottom: "24px",
                        //     paddingLeft: "16px",
                        //     paddingRight: "16px",
                        // }}
                    >
                        <NavigationButton
                            style={{ marginBottom: "8px" }}
                            type={"primary"}
                            onClick={onForgotPassword}
                        >
                            {recoveryModalTranslations.letsGo}
                        </NavigationButton>
                        <NavigationButton
                            style={{ marginBottom: "8px" }}
                            type={"secondary"}
                            onClick={handleClose}
                        >
                            {recoveryModalTranslations.cancel}
                        </NavigationButton>
                    </NavigationButtonGroup>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
}

export default RecoveryForgotPasswordModal;
