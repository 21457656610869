
const Es = "es";
const En = "en";

export const translations = {
  en: {
        "RetunToChatButton": "Return to chat",
        "Close": "Close",
        "Help": "Help",
        "PhoneSupport": "Phone Support",
        "OffBusinessHours": "Off Business Hours",
        SHOW_USER_NOT_FOUND: "Account could not be found. Try again or call (855) 708-4590 for help.",
        NavButton:{
            "BackButton": "Back",
            "NextButton": "Next",
            "Confirm": "Confirm",
            "Close": "Close",
            "Cancel": "Cancel",
            "ExitButton": "Exit",
            "ProvideFeedbackButton": "Provide Feedback",
            "BeginButton": "Begin",
            "StartOverButton": "Start over",
            "SubmitButton": "Submit"
        },
        WelcomePage: {
            "Title": "Get Your Tax Form",
            "Body": "You're just a few easy steps away from downloading your tax form. Let's get started!",
            "Body1" :"we can help you keep or cancel your plan. ",
            "alt": "Medi-Cal and Covered California Logos"
        },
        AuthenticationPage: {
            "header": "Verify Your Identity",
            "subHeader": "Enter your information below.",
            "username": "Username",
            "userNamePlaceholder": "Enter username",
            "validationUsernameMessage": "Please enter a valid username.",
            "password": "Password",
            "forgotUsername": "Forgot your username?",
            "passwordPlaceholder": "Enter password",
            "validationPasswordMessage": "Please enter a valid password.",
            "forgotYourPassword": "Need to reset your password?",
            "privacyStatement": "Privacy Statement",
            "submit": "Submit",
        },
        RecoveryModal: {
            Title:"Let’s get your username.",
            bodyText: "You’ll come back here to get your tax form once you are done. ",
            letsGo: "Let's go",
            cancel: "Cancel"
        },
        RecoveryModalForPasswordReset: {
            Title:"Reset Your Password.",
            bodyText: "You’ll come back here to get your tax form once you are done. ",
            letsGo: "Let's go",
            cancel: "Cancel"
        },
        PasscodePage: {
            subHeader: "Enter One-time Passcode",
            bodyText: "Sent to",   
            enterPasscode: "Enter One-time Passcode",
            validationMessage: "Please enter a valid passcode.",
            validationMessage1: "One-time passcode is invalid.",
            didNotGetPasscode: "Didn’t get your passcode?",
        },
        InvalidPasswordPage: {
            subHeader: "Your account has been temporarily locked because you tried to log in too many times. You can try again later or call us at (800) 300-1506 for help."
        },
        InvalidOTPErrorPage: {
            subHeader: "Your account has been temporarily locked because you entered an incorrect one-time passcode too many times. You can try again later or call us at (800) 300-1506 for help.",
        },
        AccountLockPage: {
            "subHeader": "Account Locked",
            "bodyText": "Your account is locked. Call the Service Center for help. ",
            "serviceCenter": "Covered California Service Center",
            "phoneNumber": "(855) 708-4590",
            "serviceHours": "Service Hours",
            "extendedHours": "Extended Hours",
            "Exit": "Exit"
        },
        ServiceUnavailablePage: {
            Title: "Something went wrong",
            subHeader: "Sorry, but we’re not able to complete this request right now. Please try again later.",
        },
        SessionExpiredPage: {
            subHeader: "Your session has expired. To start over, click “Exit” below.",
        },
        PasscodeModal: {
            subHeader: "Didn’t get the passcode?",
            bodyText: "Call us at (800) 300-1506.",
            close: "Close",
        },
        OtpTypePage: {
            "subHeader": "Receive One-time Passcode",
            "bodyText": "To verify your identity, how would you like to receive your one-time passcode?",
            "email": "To your email",
            "phone": "To your cell phone",
            "submit": "Submit",
            "validationMessage": " You must make a selection to continue.",
            "Note": "*Standard text message rates apply.",
            "token": " Use expired token",
            "Submit": "Submit",
        },
        DownloadPage: {
            subHeader: "Here’s your 1095-A form.",
            bodyText: "Click on the link below to download it.",
            download: "Download",
            closeButton: "Close",
            downloadLinkText: "Download PDF",
        },
        EncryptionDecryptionErrorPage: {
            Title: "Something went wrong",
            subHeader: "This request can not be completed. Please try again later.",
        },
        ServiceCenterPage: {
            "subHeader": "Verification Method Needed.",
            "bodyText": "Your email or phone number is not on file to send a one-time passcode. Call the Service Center to update your account.",
            "serviceCenter": "Covered California Service Center",
            "phoneNumber": "(855) 708-4590",
            "serviceHours": "Service Hours",
            "extendedHours": "Extended Hours",
            "Exit": "Exit"
        },
        NoTaxformPage: {
            "subHeader": "Tax Forms Unavailable",
            "bodyText": "There are no tax forms available in your account. Contact the Service Center for more help. ",
            "serviceCenter": "Covered California Service Center",
            "phoneNumber": "(855) 708-4590",
            "serviceHours": "Service Hours",
            "extendedHours": "Extended Hours",
            "Exit": "Exit"
        },
        TaxFormPasswordExceededAttemptsPage: {
            subHeader: "Too many login attempts. Try again in 20 minutes or call (800) 300-1506 for help.",
        },
        TTaxFormPasscodeExceededAttemptsPage: {
            subHeader: "Your account has been temporarily locked because you entered an incorrect one-time passcode too many times. You can try again later or call us at (800) 300-1506 for help.",
        },

    },
    es: {
        "RetunToChatButton": "Volver al chat",
        "Close": "Cerrar",
        "Help": "Ayuda",
        "PhoneSupport": "Soporte telefónico",
        "OffBusinessHours": "Fuera del horario laboral",
        SHOW_USER_NOT_FOUND: "No se pudo encontrar la cuenta. Inténtelo de nuevo o llame al (855) 708-4590 para obtener ayuda.",
        NavButton:{
            "BackButton": "Atrás",
            "NextButton": "Siguiente",
            "Confirm": "Confirmar",
            "Close": "Cerrar",
            "Cancel": "Cancelar",
            "ExitButton": "Salir",
            "ProvideFeedbackButton": "Proporcionar comentarios",
            "BeginButton": "Comenzar",
            "StartOverButton": "Empezar de nuevo",
            "SubmitButton": "Enviar"
        },
        WelcomePage: {
            "Title": "Su cobertura, su elección",
            "Body": "En unos pocos pasos sencillos, ",
            "Body1" :"podemos ayudarle a mantener o cancelar su plan. ",
            "alt": "Medi-Cal y Covered California Logos"
        }
    }
};