import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useTaxForm from "../../Hooks/useTaxForm";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";
import TaxFormjson from "../../Images/TaxForms.json";
import Lottie from "react-lottie";

function WelcomePage(props) {
const { next , closeTaxForm} = useTaxForm();
    const { currentLanguage } = useLanguage();
    const { sendLoggingEventToBackend, onNext } = props;
    const welcomePageTranslations = translations[currentLanguage]["WelcomePage"];
    const NavButton = translations[currentLanguage]["NavButton"];
    const lang = currentLanguage;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: TaxFormjson,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const handleNextClick = () => {
        onNext();
    
        props.sendLoggingEventToBackend({
          intentName: lang === "en" ? "TaxFormsGettingStarted" : "TaxFormsGettingStartedSpanish",
          text: lang === "en" ? "TaxFormsGettingStarted" : "TaxFormsGettingStartedSpanish"
        });
      };
      
    return (
        <PageLayout>
            <PageLayout.Content>
            <div style={{ textAlign: "center", marginTop: "32px", marginBottom
            : "-16px"
             }}>
                    <Lottie
                        options={defaultOptions}
                        height={96}
                        width={96}
                    />
                </div>
                <Content welcomePageTranslations={welcomePageTranslations} NavButton={NavButton}/>
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={handleNextClick} type="primary" >
                    {NavButton.BeginButton}
                </NavigationButton>
                <NavigationButton  onClick={()=>closeTaxForm()} type="secondary">
                    {NavButton.ExitButton}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content({ welcomePageTranslations }) {
    return (
        <div
            style={{
                fontSize: '21px',
                lineHeight: '29.4px',
                fontWeight: 'normal',
                margin: '0',
                paddingTop:'32px'
            }}
        >
            <SubHeader
                style={{ display: 'flex', marginBottom: '8px', marginTop: '0px' }}
                subHeader={welcomePageTranslations.Title}
            />
            <Body
                bodyText={
                    <>
                        {welcomePageTranslations.Body}
                    </>
                }
            />
        </div>
    );
}

export default WelcomePage;