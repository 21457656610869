import React from 'react';
import Modal from '../../UiKit/Modal/Modal';
import NavigationButton, {NavigationButtonGroup} from '../../UiKit/Wizard/NavigationButton/NavigationButton'
import useTaxForm from '../../Hooks/useTaxForm';
import { useLanguage } from '../../UiKit/Wizard/langugeContext';
import { translations } from '../AffordabilityToolWizard/translations'
import useChatbot from '../../Hooks/useChatbot';

function CloseModal({type}) {
    const { closeCloseModal, minimize } = useTaxForm();
    const { currentLanguage } = useLanguage();
    const { closeTaxFormWizard } = useChatbot();
    const closeTranslations = translations[currentLanguage]["CloseModal"];
    return (
        <Modal type={type}>
             <Modal.Dialog style={{ height: "266px", width: "280px" }}>
                <Modal.Header>
                    <Modal.Close onClick={()=>closeCloseModal()}/>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title>{closeTranslations.ModalTitle}</Modal.Title>
                    <Modal.Content>
                        {closeTranslations.ModalContent}
                    </Modal.Content>
                    <NavigationButtonGroup
                    >
                        <NavigationButton style={{marginBottom: "8px"}} type={"primary"} onClick={()=>closeCloseModal()}>{closeTranslations.returnButton}</NavigationButton>
                        <NavigationButton style={{marginBottom: "8px"}} type={"secondary"} onClick={()=>closeTaxFormWizard()}>{closeTranslations.exitButton}</NavigationButton>
                    </NavigationButtonGroup>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    )
}

export default CloseModal;